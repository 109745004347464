<template>
  <div id="header">
    <div class="header_in container">
      <h1>
        <router-link to="/"><img src="@/assets/logo.png" alt="한양대학교" /></router-link>
      </h1>
      <div @click="openTrue" class="sitemap">
        <span></span>
        <span class="middle"></span>
        <span></span>
      </div>
    </div>
    <div class="menu xl:block hidden">
      <ul>
        <li class="one">
          <router-link to="/greeting">학회 소개</router-link>
          <dl>
            <dd><router-link to="/greeting">인사말</router-link></dd>
            <dd><router-link to="/history">연혁</router-link></dd>
            <dd><router-link to="/curri">커리 큘럼</router-link></dd>
          </dl>
        </li>
        <li class="two">
          <router-link to="/acting">회원소개</router-link>
          <dl>
            <dd><router-link to="/acting">액팅 회원</router-link></dd>
            <dd><router-link to="/alumni">졸업생</router-link></dd>
          </dl>
        </li>
        <li class="three">
          <router-link to="/recruit">가입안내</router-link>
          <dl>
            <dd><router-link to="/recruit">모집 안내</router-link></dd>
            <dd><router-link to="/qna">FAQ</router-link></dd>
            <dd><router-link to="/notice">공지사항</router-link></dd>
          </dl>
        </li>
        <li class="four">
          <router-link to="/journal">자료실</router-link>
          <dl>
            <dd><router-link to="/journal">학회지</router-link></dd>
            <dd><router-link to="/activities">정기 활동</router-link></dd>
            <dd><router-link to="/album">사진첩</router-link></dd>
          </dl>
        </li>
      </ul>
      <div class="bg"></div>
    </div>
    <div
      x-description="Mobile menu, show/hide based on menu open state."
      class="xl:hidden"
      v-show="openInfo.open"
      aria-modal="true"
    >
      <div
        x-description="Background backdrop, show/hide based on slide-over state."
        class="fixed inset-0 z-10"
        @click="openFalse"
      ></div>
      <div
        class="fixed inset-y-0 right-0 z-[9999] w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
      >
        <div class="flex items-center justify-between">
          <div></div>
          <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="openFalse">
            <span class="sr-only">Close menu</span>
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <div class="-mx-3">
                <button
                  type="button"
                  class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  aria-controls="disclosure-1"
                  @click="changeOpenMenu1"
                  aria-expanded="false"
                  x-bind:aria-expanded="open.toString()"
                >
                  학회 소개
                  <svg
                    class="h-5 w-5 flex-none"
                    x-description="Expand/collapse icon, toggle classes based on menu open state."
                    x-state:on="Open"
                    x-state:off="Closed"
                    :class="{ 'rotate-180': openInfo.openMenu1 }"
                    style="transition: all 0.4s ease"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
                <div
                  class="mt-2 space-y-2"
                  x-description="'Product' sub-menu, show/hide based on menu state."
                  id="disclosure-1"
                  v-show="openInfo.openMenu1"
                >
                  <router-link
                    to="/greeting"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >인사말</router-link
                  >
                  <router-link
                    to="/history"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >연혁</router-link
                  >
                  <router-link
                    to="/curri"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >커리큘럼</router-link
                  >
                </div>
              </div>
              <div x-data="{ openMenu2: false }" class="-mx-3">
                <button
                  type="button"
                  class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  aria-controls="disclosure-1"
                  @click="changeOpenMenu2"
                  aria-expanded="false"
                  x-bind:aria-expanded="open.toString()"
                >
                  회원 소개
                  <svg
                    class="h-5 w-5 flex-none"
                    x-description="Expand/collapse icon, toggle classes based on menu open state."
                    x-state:on="Open"
                    x-state:off="Closed"
                    :class="{ 'rotate-180': openInfo.openMenu2 }"
                    style="transition: all 0.4s ease"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
                <div
                  class="mt-2 space-y-2"
                  x-description="'Product' sub-menu, show/hide based on menu state."
                  id="disclosure-1"
                  v-show="openInfo.openMenu2"
                >
                  <router-link
                    to="/acting"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >액팅 회원</router-link
                  >
                  <router-link
                    to="/alumni"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >졸업생</router-link
                  >
                </div>
              </div>
              <div x-data="{ openMenu3: false }" class="-mx-3">
                <button
                  type="button"
                  class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  aria-controls="disclosure-1"
                  @click="changeOpenMenu3"
                  aria-expanded="false"
                  x-bind:aria-expanded="open.toString()"
                >
                  가입 안내
                  <svg
                    class="h-5 w-5 flex-none"
                    x-description="Expand/collapse icon, toggle classes based on menu open state."
                    x-state:on="Open"
                    x-state:off="Closed"
                    :class="{ 'rotate-180': openInfo.openMenu3 }"
                    style="transition: all 0.4s ease"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
                <div
                  class="mt-2 space-y-2"
                  x-description="'Product' sub-menu, show/hide based on menu state."
                  id="disclosure-1"
                  v-show="openInfo.openMenu3"
                >
                  <router-link
                    to="/recruit"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >모집 안내</router-link
                  >
                  <router-link
                    to="/qna"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >FAQ</router-link
                  >
                  <router-link
                    to="/notice"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >공지사항</router-link
                  >
                </div>
              </div>
              <div x-data="{ openMenu4: false }" class="-mx-3">
                <button
                  type="button"
                  class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  aria-controls="disclosure-1"
                  @click="changeOpenMenu4"
                  aria-expanded="false"
                  x-bind:aria-expanded="open.toString()"
                >
                  자료실
                  <svg
                    class="h-5 w-5 flex-none"
                    x-description="Expand/collapse icon, toggle classes based on menu open state."
                    x-state:on="Open"
                    x-state:off="Closed"
                    :class="{ 'rotate-180': openInfo.openMenu4 }"
                    style="transition: all 0.4s ease"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
                <div
                  class="mt-2 space-y-2"
                  x-description="'Product' sub-menu, show/hide based on menu state."
                  id="disclosure-1"
                  v-show="openInfo.openMenu4"
                >
                  <router-link
                    to="/journal"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >학회지</router-link
                  >
                  <router-link
                    to="/activities"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >정기 활동</router-link
                  >
                  <router-link
                    to="/album"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >사진첩</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {}
  },
  computed: {
    openInfo() {
      return this.$store.state.openInfo
    }
  },
  methods: {
    openTrue() {
      let info = this.openInfo
      info.open = true
      this.$store.commit('updateOpenInfo', info)
    },
    openFalse() {
      let info = this.openInfo
      info.open = false
      this.$store.commit('updateOpenInfo', info)
    },
    changeOpenMenu1() {
      let info = this.openInfo
      info.openMenu1 = !info.openMenu1
      this.$store.commit('updateOpenInfo', info)
    },
    changeOpenMenu2() {
      let info = this.openInfo
      info.openMenu2 = !info.openMenu2
      this.$store.commit('updateOpenInfo', info)
    },
    changeOpenMenu3() {
      let info = this.openInfo
      info.openMenu3 = !info.openMenu3
      this.$store.commit('updateOpenInfo', info)
    },
    changeOpenMenu4() {
      let info = this.openInfo
      info.openMenu4 = !info.openMenu4
      this.$store.commit('updateOpenInfo', info)
    }
  }
}
</script>
<style scoped>
#header {
  background: #fff;
  border-bottom: 1px solid #ddd;
}

#header .header_in {
  margin: 0 auto;
  padding: 20px 12px;
  padding-bottom: 30px;
  position: relative;
}
.header_in h1 {
  position: relative;
  z-index: 50;
  width: 169px;
}

#header .menu {
  position: Absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
}
.menu ul {
  overflow: hidden;
  clear: both; /*width:560px;*/
  position: absolute;
  left: 50%;
  top: 34px;
  z-index: 99;
}
.menu li {
  float: left;
  position: relative;
}
.menu li > a {
  font-size: 18px;
  color: #3a3a3a;
  font-weight: 600;
  padding: 0 0 0 15px;
  line-height: 120%;
  transition: color 0.3s ease;
}
.menu li > a:before {
  content: '';
  display: block;
  width: 0;
  height: 4px;
  background: #002b63;
  position: Absolute;
  left: 0;
  top: 51px;
  transition: all 0.3s ease;
}
.menu dl {
  padding-left: 15px;
  margin-top: 55px;
  height: 0;
  transition: all 0s ease;
  overflow: hidden;
}
.menu dd a {
  letter-spacing: 0;
  line-height: 145%;
  font-size: 16px;
  margin-bottom: 11px;
  display: block;
  transition: all 0.3s ease-out;
}
.menu dd:last-child a {
  margin-bottom: 0;
}

.menu li.one {
  width: 150px;
}
.menu li.two {
  width: 150px;
}
.menu li.three {
  width: 150px;
}
.menu li.four {
  width: 150px;
}
.menu li.five {
  width: 150px;
}
.menu li.six {
  width: 150px;
}

.menu .bg {
  position: absolute;
  top: 88px;
  width: 100%;
  height: 0;
  background: #ffffffde;
  transition: all 0.2s ease;
  border: none;
  z-index: 20;
}
.menu ul:hover + .bg {
  height: 280px;
  transition: all 0.3s ease;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}
.menu ul:hover dl {
  height: 280px;
  transition: all 0.4s ease;
}
.menu li:hover > a:before {
  width: 100%;
  transition: all 0.3s ease;
}
.menu li:hover > a {
  color: #004483;
  transition: color 0.3s ease;
}
.menu dd a:hover {
  color: #004483;
  font-weight: 600;
  transition: all 0.3s ease-out;
}
.header_in .sitemap {
  display: block;
  width: 24px;
  height: 19px;
  position: absolute;
  right: 16px;
  top: 35px;
  overflow: hidden;
  clear: both;
  z-index: 50;
}
@media (min-width: 1280px) {
  .header_in .sitemap {
    display: none;
  }
}
.header_in .sitemap span {
  display: block;
  width: 100%;
  height: 3px;
  background: #707070;
  float: right;
  transition: all 0.2s ease;
}
.header_in .sitemap span.middle {
  margin: 5px 0;
  transition: all 0.2s ease;
}
.header_in .sitemap:hover span {
  background: #004483;
  transition: all 0.2s ease;
}
.header_in .sitemap:hover span.middle {
  width: 80%;
  transition: all 0.2s ease;
}
</style>
