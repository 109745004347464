<template>
  <div class="visual cursor-pointer">
    <div class="visual_img" :style="{ backgroundImage: 'url(' + info.img + ')' }">
      <div v-if="type === 1" class="tag2 shadow-lg">File<br />Album</div>
      <div v-else class="tag shadow-lg">Photo<br />Album</div>
    </div>
    <div class="visual_title">
      <h5>{{ info.title }}</h5>
      <p>{{ info.date }}</p>
    </div>
    <div class="visual_desc">
      <p>{{ info.desc }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Visual',
  props: {
    info: Object,
    type: Number
  }
}
</script>
<style scoped>
.visual {
  display: flex;
  width: 320px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  flex-shrink: 0;
}
.visual_img {
  width: 320px;
  height: 216px;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
}
.visual_img .tag {
  background-color: #2a804c;
  width: 60px;
  height: 50px;
  padding: 12px 12.82px 12.3px 13px;
  color: #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.2px;
}
.visual_img .tag2 {
  background-color: #946753;
  width: 60px;
  height: 50px;
  padding: 12px 12.82px 12.3px 13px;
  color: #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.2px;
}
.visual_title {
  flex-shrink: 0;
}
.visual_title h5 {
  color: #222;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}
.visual_title p {
  color: #adadad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.visual_desc {
  width: 100%;
  height: 40px;
}
.visual_desc p {
  color: #777;
  height: 40px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.5px;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
