import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/greeting',
    name: 'greeting',
    component: () => import(/* webpackChunkName: "about" */ '../views/About/GreetingView.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import(/* webpackChunkName: "about" */ '../views/About/HistoryView.vue')
  },
  {
    path: '/curri',
    name: 'curri',
    component: () => import(/* webpackChunkName: "about" */ '../views/About/CurriView.vue')
  },
  {
    path: '/acting',
    name: 'acting',
    component: () => import(/* webpackChunkName: "member" */ '../views/Member/ActingView.vue')
  },
  {
    path: '/alumni',
    name: 'alumni',
    component: () => import(/* webpackChunkName: "member" */ '../views/Member/AlumniView.vue')
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: () => import(/* webpackChunkName: "regist" */ '../views/Regist/RecruitView.vue')
  },
  {
    path: '/qna',
    name: 'qna',
    component: () => import(/* webpackChunkName: "regist" */ '../views/Regist/QnaView.vue')
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import(/* webpackChunkName: "regist" */ '../views/Regist/NoticeView.vue')
  },
  {
    path: '/journal',
    name: 'journal',
    component: () => import(/* webpackChunkName: "refer" */ '../views/Refer/JournalView.vue')
  },
  {
    path: '/activities',
    name: 'activities',
    component: () => import(/* webpackChunkName: "refer" */ '../views/Refer/ActivitiesView.vue')
  },
  {
    path: '/album',
    name: 'album',
    component: () => import(/* webpackChunkName: "refer" */ '../views/Refer/AlbumView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  store.commit('initOpenInfo')
  next()
})

export default router
