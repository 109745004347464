import { createStore } from 'vuex'

export default createStore({
  state: {
    openInfo: {
      open: false,
      openMenu1: false,
      openMenu2: false,
      openMenu3: false,
      openMenu4: false
    }
  },
  getters: {
    getOpenInfo(state) {
      return state.openInfo
    }
  },
  mutations: {
    updateOpenInfo(state, openInfo) {
      state.openInfo = openInfo
    },
    initOpenInfo(state) {
      state.openInfo.open = false
      state.openInfo.openMenu1 = false
      state.openInfo.openMenu2 = false
      state.openInfo.openMenu3 = false
      state.openInfo.openMenu4 = false
    }
  },
  actions: {},
  modules: {}
})
