<template>
  <div class="home">
    <div class="fade-in container mx-auto">
      <div
        class="relative bg-cover bg-bottom w-full md:h-[650px] h-[500px]"
        :style="{ backgroundImage: 'url(' + main.imgUrl + ')' }"
      >
        <div
          class="bg-black/70 md:w-[680px] md:h-[400px] h-full absolute top-1/2 right-0 -translate-y-1/2"
        >
          <div class="flex flex-col px-16 py-14 justify-between h-full">
            <div class="flex flex-col gap-5">
              <div class="w-[24px] h-[10px] rounded-full bg-[#FCC92F]"></div>
              <h2 class="text-3xl font-bold text-white">{{ apiText.mainTitle }}</h2>
            </div>
            <div class="flex flex-col gap-6">
              <div>
                <p class="text-md font-medium text-white">
                  {{ apiText.recruitKr }}
                </p>
                <p class="text-md font-medium text-white">
                  {{ apiText.recruitEng }}
                </p>
              </div>
              <router-link
                to="/recruit"
                class="max-w-[160px] inline-flex px-5 py-3 justify-between items-center bg-[#FCC92F] rounded shadow-lg"
              >
                <p class="text-[#222] text-md font-bold">자세히보기</p>
                <img src="@/assets/arrow1.png" alt="화살표" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap my-6 p-12" style="border-bottom: 3px solid #777">
        <div
          class="md:basis-1/2 basis-full flex justify-between items-center p-6 md:border-r-2 md:border-slate-200 md:border-solid"
        >
          <div class="flex flex-row items-center gap-6">
            <img class="w-8 h-8" src="@/assets/icon1.png" alt="정규과정 소개 아이콘" />
            <p class="text-black font-semibold text-xl">정규과정 소개</p>
          </div>
          <router-link to="/curri">
            <div class="flex flex-row items-center gap-3 mr-5">
              <p class="hidden lg:block font-normal text-lg">정규과정 및 대외활동 소개</p>
              <img class="w-5 h-5 opacity-70" src="@/assets/arrow2.png" alt="" />
            </div>
          </router-link>
        </div>
        <div class="md:basis-1/2 basis-full flex justify-between items-center p-6 lg:pl-14">
          <div class="flex flex-row items-center gap-6">
            <img class="w-8 h-8" src="@/assets/icon2.png" alt="자료실 아이콘" />
            <p class="text-black font-semibold text-xl">자료실</p>
          </div>
          <router-link to="/activities">
            <div class="flex flex-row items-center gap-3 mr-5">
              <p class="hidden lg:block font-normal text-lg">지난학기 활동 내용 보러가기</p>
              <img class="w-5 h-5 opacity-70" src="@/assets/arrow2.png" alt="" />
            </div>
          </router-link>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="basis-full lg:basis-3/4 lg:pr-12 px-4 lg:px-0">
          <div
            class="flex flex-wrap gap-16 mt-[40px] pb-[50px]"
            style="border-bottom: 1px solid #ddd"
          >
            <Visual
              v-for="(info, i) in infos"
              :key="i"
              :info="info"
              @click="this.$router.push('/activities')"
            ></Visual>
          </div>
          <div class="flex flex-col py-12" style="border-bottom: 1px solid #ddd">
            <div class="flex justify-between w-full mb-4">
              <h5
                @click="this.$router.push('/notice')"
                class="text-[#222] font-semibold text-xl cursor-pointer"
              >
                공지사항
              </h5>
              <button
                @click="this.$router.push('/notice')"
                class="hover:underline hover:underline-offset-4 px-4 py-1"
              >
                + 더보기
              </button>
            </div>
            <div class="w-[22px] h-[2px] bg-black mb-6"></div>
            <div v-for="item in noticeItems" :key="item.id" class="items">
              <div class="flex justify-between py-3">
                <div class="flex gap-2 items-center">
                  <div class="rounded bg-[#333] px-[6px] py-[4px] text-gray-400">공지</div>
                  <div
                    @click="this.$router.push('/notice')"
                    class="text-[#444] hover:underline hover:underline-offset-4 text-base cursor-pointer"
                  >
                    {{ item.title }}
                  </div>
                </div>
                <div class="text-[#A9A9A9]">{{ item.createdAt }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="basis-full lg:basis-1/4 flex flex-col px-4 lg:px-0">
          <div class="flex flex-col gap-6 pb-9" style="border-bottom: 1px solid #ddd">
            <p class="font-semibold text-lg text-[#222222]">지원문의</p>
            <div class="flex">
              <p class="basis-1/3 text-[#333333]">{{ apiText.contact.phone.label }}</p>
              <p class="basis-2/3 text-[#333333] text-xl font-semibold">
                {{ apiText.contact.phone.content }}
              </p>
            </div>
            <div class="flex">
              <p class="basis-1/3 text-[#333333]">{{ apiText.contact.email.label }}</p>
              <p class="basis-2/3 text-[#333333] text-xl font-semibold">
                {{ apiText.contact.email.content }}
              </p>
            </div>
            <div class="flex">
              <p class="basis-1/3 text-[#333333]">{{ apiText.contact.insta.label }}</p>
              <p class="basis-2/3 text-[#333333] text-xl font-semibold">
                {{ apiText.contact.insta.content }}
              </p>
            </div>
          </div>
          <a :href="apiText.fileUrl">
            <div
              class="flex justify-between items-center py-8"
              style="border-bottom: 1px solid #ddd"
            >
              <p class="basis-2/3 font-semibold text-base text-[#222222]">
                브로셔 파일<br />다운로드
              </p>
              <div class="w-[70px] h-[70px] shrink-0 rounded-full bg-[#EEEBEB]">
                <img
                  class="w-full h-full"
                  src="@/assets/banner2icon.png"
                  alt="브로셔 파일 다운로드"
                />
              </div>
            </div>
          </a>
          <a :href="apiText.instaUrl" target="_blank">
            <div class="flex justify-between items-center py-8">
              <p class="basis-2/3 font-semibold text-base text-[#222222]">
                HYFIN 인스타그램<br />바로가기
              </p>
              <div
                class="w-[70px] h-[70px] shrink-0 rounded-full bg-[#EEEBEB] flex justify-center items-center"
              >
                <img class="w-[35px] h-[35px]" src="@/assets/instagramicon.png" alt="hyfin Insta" />
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="h-[100px]"></div>
  </div>
</template>

<script>
import Visual from '@/components/Visual.vue'
import { getMainPage, getReferenceActivityList, getRecruitNotices } from '@/apis/main.js'
export default {
  name: 'HomeView',
  data() {
    return {
      main: {
        imgUrl: require('@/assets/main.jpg')
      },
      apiText: {
        mainTitle: '2023 정기 리쿠르팅 안내',
        recruitKr: '모집 기간 : June 5th ~ August 14th, 2023',
        recruitEng: 'Application Period : March 13th, 2022 ~ April 21th, 2023',
        contact: {
          phone: { label: '22-1 학회장', content: '010-5736-5026' },
          email: { label: 'Email', content: 'hyu.hyfin@gmail.com' },
          insta: { label: 'Insta', content: '@hyu_hyfin' }
        },
        fileUrl: '',
        instaUrl: 'https://www.instagram.com/hyu_hyfin/'
      },
      infos: [
        {
          id: '0',
          title: '정기 활동 1호',
          date: '2023/10/15',
          desc: '안녕하세요 이글은 정기 활동에 관한 설명 예시 문구 입니다. 내용은 2줄만 미리보기 할 수 있습니다. 안녕하세요 안녕하세요 안녕하세요',
          img: require('@/assets/sample1.jpg')
        },
        {
          id: '1',
          title: '정기 활동 2호',
          date: '2023/10/15',
          desc: '안녕하세요 이글은 정기 활동에 관한 설명 예시 문구 입니다. 내용은 2줄만 미리보기 할 수 있습니다. 안녕하세요 안녕하세요 안녕하세요',
          img: require('@/assets/sample1.jpg')
        },
        {
          id: '2',
          title: '정기 활동 3호',
          date: '2023/10/15',
          desc: '안녕하세요 이글은 정기 활동에 관한 설명 예시 문구 입니다. 내용은 2줄만 미리보기 할 수 있습니다. 안녕하세요 안녕하세요 안녕하세요',
          img: require('@/assets/sample1.jpg')
        }
      ],
      noticeItems: [
        {
          idx: 3,
          title: '하이핀 공지3',
          createdAt: '2022-07-05',
          updatedAt: '',
          contents: `안녕하세요. 하이핀입니다.

현재 서비스 개편으로 인해 고객센터를 카카오톡으로만 운영하고 있습니다.
이용에 참고해 주시면 감사드리겠습니다.

더 나은 서비스를 위해 노력하겠습니다.

감사드립니다.`
        },

        {
          idx: 2,
          title: '하이핀 공지2',
          createdAt: '2022-07-05',
          updatedAt: '',
          contents: `안녕하세요. 하이핀입니다.

현재 서비스 개편으로 인해 고객센터를 카카오톡으로만 운영하고 있습니다.
이용에 참고해 주시면 감사드리겠습니다.

더 나은 서비스를 위해 노력하겠습니다.

감사드립니다.`
        },
        {
          idx: 1,
          title: '하이핀 공지',
          createdAt: '2022-07-05',
          updatedAt: '',
          contents: `안녕하세요. 하이핀입니다.

현재 서비스 개편으로 인해 고객센터를 카카오톡으로만 운영하고 있습니다.
이용에 참고해 주시면 감사드리겠습니다.

더 나은 서비스를 위해 노력하겠습니다.

감사드립니다.`
        }
      ]
    }
  },
  async mounted() {
    // get mainTitle, recruitKr, recruitEng, contact, fileUrl from api
    let apiTextData = await getMainPage()
    this.apiText.mainTitle = apiTextData.mainTitle
    this.apiText.recruitKr = apiTextData.recruitKr
    this.apiText.recruitEng = apiTextData.recruitEng
    this.apiText.contact.phone.label = apiTextData.contactLabel
    this.apiText.contact.phone.content = apiTextData.contactPhone
    this.apiText.fileUrl = apiTextData.brochurePdfUrl

    let referenceActivityData = await getReferenceActivityList(3)
    this.infos = []
    console.log(referenceActivityData)
    for await (var activity of referenceActivityData.activityList) {
      this.infos.push({
        id: activity.idx,
        title: activity.title,
        date: activity.createdAt.split('T')[0], // 2023-10-15T00:00:00.000Z
        desc: activity.content,
        img: activity.pictureUrl
      })
    }

    let noticeData = await getRecruitNotices(3)
    this.noticeItems = []
    for await (var notice of noticeData.notices) {
      this.noticeItems.push({
        idx: notice.idx,
        title: notice.title,
        createdAt: notice.createdAt.split('T')[0],
        updatedAt: notice.updatedAt.split('T')[0],
        contents: notice.content // HTML
      })
    }
  },
  components: {
    Visual
  }
}
</script>
