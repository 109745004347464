<template>
  <Header></Header>
  <router-view />
  <Footer></Footer>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 155%;
  letter-spacing: normal;
}
body,
html {
  width: 100%;
  font-size: 14px;
  color: #666;
  font-family: Malgun Gothic, 맑은 고딕, Dotum, sans-serif;
}
* {
  font-family: Malgun Gothic, 맑은 고딕, Dotum, sans-serif;
}
ol,
ul,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  font-size: 13px;
}
table caption {
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  font-size: 13px;
}
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  line-height: 155%;
  letter-spacing: normal;
}
dt {
  display: block;
}
hr {
  display: none;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: #666;
}
address {
  font-style: normal;
}
input,
select,
img {
  vertical-align: middle;
}
input:focus,
textarea:focus,
button:focus {
  outline: none;
}
.input_search {
  border: 0;
  background: none;
  height: 20px;
  width: 155px;
  font-size: 12px;
  color: #909496;
  font-family: Malgun Gothic, 맑은 고딕, Dotum, sans-serif;
}
/* 바로가기 */
#accessibilityMenu {
  position: absolute;
  overflow: hidden;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
}

/* Common */
.line_bg {
  height: 2px;
  background-color: #f5f5f5;
}
.img_bor {
  border: 1px solid #ddd;
}

.pointDblue {
  color: #002b63 !important;
}
.kor {
  letter-spacing: -0.65pt !important;
}

/* 레이아웃 */
#wrap {
  position: relative;
  width: 100%;
  min-width: 1200px;
}
#visual {
  overflow: hidden;
  width: 100%;
  text-align: center;
  height: 220px;
}
.subtop_about {
  background: url('@/assets/subtop_about.jpg') no-repeat center;
}
.subtop_member {
  background: url('@/assets/subtop_member.jpg') no-repeat center;
}
.subtop_regist {
  background: url('@/assets/subtop_regist.jpg') no-repeat center;
}
.subtop_refer {
  background: url('@/assets/subtop_refer.jpg') no-repeat center;
}

#visual h3 {
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.15pt;
  line-height: 120%;
  font-size: 45px;
  padding: 65px 0 10px 0;
}
#visual h3 strong {
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  letter-spacing: 0.15pt;
  line-height: 120%;
  font-weight: 700;
}
#visual p {
  font-family: 'Open Sans', sans-serif;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  font-size: 21px;
  letter-spacing: 0.5pt;
  line-height: 120%;
}
#visual p span {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  letter-spacing: 0.5pt;
  line-height: 120%;
}

#contents {
  overflow: hidden;
  position: relative;
  padding: 0 0 150px;
}
#contents:before {
  display: block;
  content: '';
  width: 1px;
  height: 100%;
  background: #ddd;
  position: Absolute;
  top: 0;
  left: 254px;
  display: none;
}

.lnb {
  width: 100%;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 99;
}
.lnb h2 {
  border-bottom: 1px solid #3a3a3a;
  padding: 65px 0 40px 23px;
  font-weight: 700;
  line-height: 120%;
  font-size: 30px;
  color: #3a3a3a;
  font-family: 'Open Sans', sans-serif;
}
.lnb ul {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
}
.lnb li {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: calc(3% + 40px);
}
.lnb li:last-child {
  margin-right: 0;
}
.lnb li a {
  font-size: 16px;
  color: #666;
  display: block;
  line-height: 145%;
  height: 59px;
  text-align: center;
  transition: all 0.3s ease-out;
  border-bottom: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.lnb li > a:hover {
  color: #004483;
  text-decoration: none;
  border-bottom: 3px solid #004483;
  transition: all 0.3s ease-out;
}
.left_linktop {
  display: block;
  color: #004483 !important;
  font-weight: 700 !important;
  text-decoration: none;
  border-bottom: 3px solid #004483 !important;
}
.lnb li i {
  display: block;
  width: 8px;
  height: 14px;
  position: absolute;
  right: 23px;
  top: 50%;
  margin-top: -7px;
}

.lnb li.kor a {
  letter-spacing: -0.65pt;
}
/*
	.lnb .list03 li {width:33.33%}
	.lnb .list04 li {width:25%}
	.lnb .list05 li {width:20%}
	.lnb .list07 li {width:14.28%}
	.lnb .list09 li {width:11.11%}*/

.lnb dl {
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 12px 0 12px 23px;
}
.lnb dd a,
.lnb dd a:link,
.lnb dd a:visited {
  display: block;
  font-size: 14px;
  color: #777;
  letter-spacing: -0.55pt;
  margin: 6px 0;
  position: relative;
  padding-left: 11px;
  transition: all 0.2s ease;
}
.lnb dd a:before {
  content: '';
  display: block;
  width: 5px;
  height: 2px;
  position: absolute;
  left: 0;
  top: 10px;
  background: #909090;
  transition: all 0.2s ease;
}
.lnb dd a:hover {
  color: #004483;
  font-weight: 600;
  transition: all 0.2s ease;
}
.lnb dd a:hover:before {
  background: #004483;
  transition: all 0.2s ease;
}

.fade-in {
  -webkit-animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
