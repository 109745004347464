import axios from 'axios'
import { API_BASE_URL } from '../constants/settings'

const get = async (uri) => {
  try {
    let response = await axios.get(API_BASE_URL + uri, {
      timeout: 5000
    })

    return response.data
  } catch (e) {
    if (e.response?.status) {
      if (e.response?.status == 521 || e.response?.status == 502) {
        return {
          error: true,
          message: 'Failed to connect to server'
        }
      }
    }

    if (e.response?.data) {
      return e.response.data
    }

    return {
      error: true,
      message: 'Failed to connect to server'
    }
  }
}

export { get }
