<template>
  <div class="w-full">
    <div class="bg-slate-50" style="height: 140px; border-top: 1px solid #ddd">
      <div class="container mx-auto pt-5 p-4 font-semibold">
        <p class="mt-3">Email : hyu.hyfin@gmail.com</p>
        <p class="mt-3">Adress : 서울시 성동구 왕십리로 222 한양대학교 706</p>
        <p class="mt-3">ZIP Code : 04763</p>
      </div>
    </div>
    <div class="bg-slate-700" style="height: 80px">
      <div class="container mx-auto pt-10 p-4">
        <p class="text-gray-400">ⓒ 2023 HY-FIN. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer'
}
</script>
