import { get } from './common'

const getMainPage = async () => {
  return await get('academy/main')
}

const getReferenceActivityList = async (num) => {
  return await get('reference/activity/list?count=' + num)
}

const getRecruitNotices = async (num) => {
  return await get('recruit/notices?count=' + num)
}

export { getMainPage, getReferenceActivityList, getRecruitNotices }
